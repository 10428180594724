<template>
    <div
        class="flex items-start bg-gray-200 p-4 pb-3.5 rounded-xl font-ffdin text-xs leading-4 cursor-pointer"
        @click="open = !open"
    >
        <div
            ref="wrapper"
            class="flex-grow mr-2 overflow-hidden transition-all duration-1000"
            :class="{ 'max-h-3': !open }"
        >
            <div ref="items">
                <div
                    v-for="(community, index) in profiles"
                    :key="index"
                    class="mb-1 break-words"
                >
                    <div class="inline-block mr-1">
                        <span class="uppercase font-bold">{{ community.communityName }}</span> /
                    </div>
                    <div class="inline-block">{{ units(community) }}</div>
                </div>
            </div>
        </div>
        <Icon
            name="chevron-down"
            class="w-3 h-3 flex-shrink-0 flex items-center transform transition"
            :class="{ 'rotate-0': !open, 'rotate-180': open }"
        />
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    components: { Icon },

    props: {
        profiles: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            open: false,
            maxHeight: null,
        };
    },

    methods: {
        units(community) {
            return community.buildings
                .map(building => {
                    return building.buildingName + ':' + building.units.map(unit => unit.unitName).join(', ');
                })
                .join('; ');
        },
    },
};
</script>

<style scoped></style>
