<template>
    <Page
        v-if="topic"
        :title="topic.subject"
        content-class="page-paddings"
    >
        <Tabs :force-tab="forceTab">
            <Tab :title="$t('messages.details.title')">
                <TopicDetails
                    class="mt-4"
                    :topic="topic"
                />
            </Tab>
            <Tab :title="$t('messages.feed.title')"> chat </Tab>
        </Tabs>
    </Page>
</template>

<script>
import Page from '@/components/ui/Page';
import Tabs from '@/components/ui/tabs/Tabs';
import Tab from '@/components/ui/tabs/Tab';
import NotifyMixin from '@/mixins/NotifyMixin';
import TopicDetails from '@/components/messages/TopicDetails';
import EventBus from '@/utils/EventBus';
import { mapGetters } from 'vuex';

export default {
    components: { TopicDetails, Page, Tabs, Tab },

    mixins: [NotifyMixin],

    data() {
        return {
            topic: null,
            forceTab: 0,
        };
    },

    computed: {
        ...mapGetters({
            unreadTopicsCount: 'msgs/unreadTopicsCount',
        }),
    },

    async mounted() {
        const { topicId } = this.$route.params;

        try {
            this.topic = await this.$msgsDataProvider.getOne('topics', { id: topicId });

            await this.$msgsDataProvider.update('topics', {
                id: this.topic.id,
                lastReadTime: this.topic.messages[this.topic.messages.length - 1].createdAt,
            });

            // Temporary solution. Will be replaced by real WST message from backend
            EventBus.emit('mark-messaging-topic-as-read', this.topic.id);
        } catch (error) {
            this.notifyError(error.message);
        }

        this.forceTab = this.$route.hash === '#feed' ? 1 : 0;
    },
};
</script>
