<template>
    <div>
        <UnitTags :profiles="topic.profiles" />
        <div
            v-for="message in topic.messages"
            :key="message.createdAt"
            class="pt-2 pb-6"
        >
            <div class="flex justify-between items-center text-xs leading-5 whitespace-nowrap">
                <div class="whitespace-nowrap overflow-hidden overflow-ellipsis mr-3">
                    {{ message.author.firstName }} {{ message.author.lastName }}, {{ message.author.role }}
                </div>
                <div class="flex-grow" />
                <icon
                    v-if="message.attachments.length > 0"
                    name="attach"
                    class="w-3 mr-2 text-gray-400 transform rotate-45"
                />
                <div class="text-gray-400">{{ formatDate(message.createdAt) }}</div>
            </div>
            <div
                class="mt-8 richtext"
                v-html="message.body"
            ></div>
            <AttachmentsPreviews
                v-if="message.attachmentsPreviews && message.attachmentsPreviews.length > 0"
                class="mt-6"
                :items="message.attachmentsPreviews"
            />
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import CommunityTimezoneMixin from '@/mixins/CommunityTimezoneMixin';
import UnitTags from './UnitTags';
import AttachmentsPreviews from '@/components/ui/atts/AttachmentsPreviews';
import NotifyMixin from '@/mixins/NotifyMixin';

export default {
    components: { AttachmentsPreviews, UnitTags, Icon },

    mixins: [CommunityTimezoneMixin, NotifyMixin],

    props: {
        topic: {
            type: Object,
            required: true,
        },
    },

    mounted() {
        for (const message of this.topic.messages) {
            if (message.attachments.length === 0) {
                message.attachmentsPreviews = [];
                continue;
            }

            this.$attachmentsDataProvider
                .getList('attachments', { ids: message.attachments.map(({ id }) => id), preview: true })
                .then(items => {
                    message.attachmentsPreviews = items.map(item => ({
                        ...item,
                        url: null,
                        previewUrl: item.url,
                        action: 'download',
                        uploaded: true,
                    }));
                    this.$forceUpdate();
                })
                .catch(error => this.notifyError(error.message));
        }
    },

    methods: {
        formatDate(date) {
            const m = this.parseDateTime(date);
            const now = new Date();

            let format = 'D MMM YYYY, hh:mm a';

            if (m.isSame(now, 'year')) {
                format = 'D MMM, hh:mm a';
            }

            return this.formatDateTime(m, format);
        },
    },
};
</script>

<style scoped></style>
